import { ChangeEvent, useState } from "react";

export const useInput = (initialValue: string = "", regex?: RegExp) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  const reset = () => {
    setValue(initialValue);
  };

  const clear = () => {
    setValue("");
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
  ) => {
    if (error) {
      setError("");
    }
    const value = typeof event === "string" ? event : event.target.value;
    if (regex && !regex?.test(value)) return;
    setValue(value);
  };

  return {
    value,
    error,
    setError,
    onChange: handleChange,
    clear,
    reset,
  };
};
