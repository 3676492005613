import { twMerge } from "tailwind-merge";

type ButtonType = "primary" | "outlined" | "disabled" | "loading";

interface ButtonProps {
  type: ButtonType;
  rounded?: boolean;
  loading?: boolean;
  onClick?: () => void;
  text: string;
  before?: any;
  after?: any;
  className?: string;
}

const twClasses: { [key in ButtonType]: string } = {
  primary: "border-accent-primary bg-accent-primary",
  outlined: "border-accent-primary text-object-primary bg-transparent",
  disabled: "bg-placeholder border-placeholder",
  loading: "bg-placeholder border-placeholder",
};

export default function Button({
  type,
  loading = false,
  rounded = true,
  onClick,
  before,
  text,
  after,
  className,
}: ButtonProps) {
  const cn = twMerge(
    `${
      loading ? "pointer-events-none" : "pointer-events-auto"
    } flex cursor-pointer text-primary text-[12px] font-bold border justify-between items-center uppercase px-4 py-3 ${
      twClasses[loading ? "loading" : type]
    } ${rounded ? "rounded-3xl" : "rounded-md"}`,
    className
  );
  return (
    <div className={cn} onClick={onClick}>
      {before && (
        <div>
          <div className="mr-2">{before}</div>
        </div>
      )}
      <div>{text}</div>
      {(loading || after) && (
        <div>
          {loading ? (
            <div className="ml-2 w-6 animate-spin flex justify-center items-center">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_13_278)">
                  <path
                    d="M12.8332 7.00002C12.8332 10.2217 10.2215 12.8334 6.99984 12.8334C3.77818 12.8334 1.1665 10.2217 1.1665 7.00002C1.1665 3.77836 3.77818 1.16669 6.99984 1.16669"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13_278">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : (
            <div className="ml-2">{after}</div>
          )}
        </div>
      )}
    </div>
  );
}
