export default function Footer() {
  return (
    <div className="w-full fixed bottom-0">
      <div className=" m-auto text-object-secondary font-bold text-[14px] p-5 bg-background">
        <div className="flex w-1/3 justify-between max-w-7xl min-w-80">
          <div>© exan.tech 2024</div>
          <div>Help Center</div>
          <div>
            <a href="mailto:support@multik.io">support@multik.io</a>
          </div>
        </div>
      </div>
    </div>
  );
}
