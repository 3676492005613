import { Link, useLocation } from "react-router-dom";

interface MenuItemProps {
  path: string;
  icon: ({ active }: { active: boolean }) => JSX.Element;
}

export default function MenuItem({ path, icon: Icon }: MenuItemProps) {
  const location = useLocation();
  const isActive = location.pathname === `/${path}`;

  return (
    <div
      className={`text-[14px] font-bold flex items-center mb-2 pl-3 py-2 capitalize rounded-md ${
        isActive ? "text-object-primary bg-highlight" : "text-object-secondary"
      }`}
    >
      <div className="w-4">
        <Icon active={isActive} />
      </div>
      <Link className="pl-2" to={`/${path}`}>
        {path}
      </Link>
    </div>
  );
}
