import { useAuth } from "@/hooks/useAuth";
import moment from "moment";
import { useState } from "react";

const applicationStatus = {
  IN_PROGRESS: {
    text: "Setting up your environment",
    color: "text-warning",
  },
  DONE: {
    text: "The environment is set up",
    color: "text-success",
  },
};

const getStartedContent = [
  { title: "Add users", content: () => <div>Add users content</div> },
  {
    title: "Add exchange account and standalone wallets",
    content: () => (
      <div>Add exchange account and standalone wallets content</div>
    ),
  },
  { title: "Add whitelists", content: () => <div>Add whitelists content</div> },
  {
    title: "Set account limits",
    content: () => <div>Set account limits content</div>,
  },
  {
    title: "Set account and asset thresholds",
    content: () => <div>Set account and asset thresholds content</div>,
  },
];

export default function Dashboard() {
  const { user } = useAuth();
  // @ts-ignore
  const status = applicationStatus[user?.provision_setup_status];
  return (
    <div className="w-full">
      <div className="text-[14px] mb-16 min-w-[320px]">
        <div className="text-object-primary text-[18px] font-bold mb-5">
          Summary
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">Workspace: </div>
          <div className="text-accent-primary font-bold">
            {user?.domain}
          </div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">SGX domain: </div>
          <div className="font-bold text-object-primary">
            {user?.provision_domain}
          </div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">IP server: </div>
          <div className="text-object-primary">{user?.server_ip}</div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">Status: </div>
          <div className={`font-bold ${status?.color}`}>
            {status?.text}
          </div>
        </div>
        <div className="mb-4 flex">
          <div className="w-[100px]">Current plan: </div>
          <div className="font-bold text-object-primary">
            <span className="capitalize">{user?.plan.toLowerCase()}</span>{" "}
            <span className="font-normal">(paid until {moment(user?.paid_until).format('MMMM DD, YYYY')})</span>
          </div>
        </div>
      </div>
      <div className="pr-5">
        <div className="text-object-primary text-[18px] font-bold mb-5">
          Get started
        </div>
        <div>
          {getStartedContent.map(({ title, content: Content }) => {
            const [isOpened, setOpened] = useState(false);
            return (
              <div className="mb-3" key={title}>
                <div
                  className={`px-6 py-4 bg-border ${
                    isOpened ? "rounded-t-md" : "rounded-md"
                  }`}
                  onClick={() => setOpened(!isOpened)}
                >
                  <div className="text-[12px] font-bold text-object-primary">
                    {title}
                  </div>
                </div>
                {isOpened && (
                  <div className="bg-highlight px-6 py-4 rounded-b-md">
                    <Content />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
