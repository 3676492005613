import { useAuth } from "@/hooks/useAuth";
import { checkIsPassedStep, useOnboarding } from "@/hooks/useOnboarding";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Onboarding() {
  const { user } = useAuth();
  const { currentStep, currentSubStep, steps } = useOnboarding();
  const navigate = useNavigate();

  useEffect(() => {
    const isOnboarded = localStorage?.getItem("isOnboarded") !== "false";
    if (isOnboarded) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="bg-base px-10 py-8 mb-5 text-object-primary w-[852px] rounded-lg drop-shadow-xl">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
        >
          <div className="text-[20px] flex  items-center font-bold font-accent">
            <span className="mr-1">Welcome to </span>
            <svg
              className="-mt-[1px]"
              width="61"
              height="15"
              viewBox="0 0 61 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2088 4.42797C13.607 4.08986 12.9228 3.92081 12.1563 3.92081C11.2968 3.92081 10.5367 4.13219 9.87567 4.5547C9.44976 4.82706 9.09344 5.18832 8.80476 5.63593C8.56888 5.23498 8.23872 4.89062 7.81334 4.60338C7.13916 4.14833 6.35888 3.92081 5.47329 3.92081C4.66678 3.92081 3.96625 4.10944 3.37128 4.48644C2.98154 4.73361 2.67855 5.06881 2.45907 5.48883V4.15468H0V14.7657H2.61776V8.54349C2.61776 8.07548 2.70356 7.67256 2.87543 7.33418C3.04717 6.99607 3.29193 6.73627 3.60919 6.55399C3.92657 6.37197 4.28995 6.28096 4.69986 6.28096C5.12282 6.28096 5.4897 6.37197 5.80049 6.55399C6.11102 6.73627 6.35242 6.99607 6.52429 7.33418C6.69603 7.67255 6.7821 8.07548 6.7821 8.54349V14.7657H9.37996V8.54349C9.37996 8.07548 9.46576 7.67256 9.63777 7.33418C9.80937 6.99607 10.0543 6.73627 10.3715 6.55399C10.6888 6.37197 11.0523 6.28096 11.4622 6.28096C11.8852 6.28096 12.252 6.37197 12.5628 6.55399C12.8732 6.73627 13.1146 6.99607 13.2866 7.33418C13.4582 7.67255 13.5444 8.07548 13.5444 8.54349V14.7657H16.1423V7.93897C16.1423 7.14581 15.9703 6.44684 15.6267 5.84205C15.2827 5.23727 14.8102 4.76635 14.2088 4.42797Z"
                fill="white"
              />
              <path
                d="M24.9331 10.3772C24.9331 10.8452 24.8404 11.2484 24.6554 11.5865C24.4702 11.9249 24.2124 12.1881 23.8821 12.3765C23.5514 12.5651 23.1747 12.6593 22.7518 12.6593C22.3284 12.6593 21.9486 12.5651 21.6114 12.3765C21.2743 12.1881 21.0165 11.9278 20.838 11.5963C20.6596 11.2645 20.5704 10.8777 20.5704 10.4356V4.15468H17.9526V10.6502C17.9526 11.5217 18.1178 12.2823 18.4485 12.9323C18.7788 13.5826 19.2481 14.0898 19.8564 14.4538C20.4644 14.8176 21.1916 14.9999 22.0378 14.9999C22.8043 14.9999 23.4721 14.8371 24.0407 14.5123C24.4799 14.2612 24.822 13.9105 25.0719 13.465V14.7657H27.531V4.15468H24.9331V10.3772Z"
                fill="white"
              />
              <path d="M32.192 0H29.5742V14.7657H32.192V0Z" fill="white" />
              <path
                d="M39.9011 12.6397C39.4778 12.6397 39.1308 12.5749 38.8599 12.4447C38.5888 12.3148 38.3906 12.1262 38.265 11.8791C38.1392 11.6322 38.0767 11.3328 38.0767 10.9817V6.43705H40.5555V4.15467H38.0767V1.73605H35.4589V2.5554C35.4589 3.07553 35.3168 3.47211 35.0326 3.74513C34.7482 4.01816 34.3416 4.15467 33.8131 4.15467H33.6147V6.43705H35.4589V11.1182C35.4589 12.3148 35.7961 13.2413 36.4704 13.8979C37.1446 14.5546 38.0896 14.8829 39.3062 14.8829C39.5044 14.8829 39.7225 14.8697 39.9606 14.844C40.1985 14.8176 40.4099 14.7916 40.5952 14.7657V12.5812C40.4762 12.5942 40.3538 12.6074 40.2283 12.6201C40.1025 12.6333 39.9934 12.6397 39.9011 12.6397Z"
                fill="white"
              />
              <path
                d="M60.9996 12.0349H58.3818V14.7657H60.9996V12.0349Z"
                fill="#C91CFF"
              />
              <path
                d="M45.0748 4.15468H42.457V14.7657H45.0748V4.15468Z"
                fill="white"
              />
              <path
                d="M57.0334 4.15468H53.8009L49.7159 8.36134V0H47.0981V14.7657H49.7159V11.5427L51.116 10.0564L54.1381 14.7657H57.1127L53.0474 8.42655L57.0334 4.15468Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="text-[14px] font-bold py-3 font-accent">
            Let's start setting up your workspace
          </div>
          {steps.map(
            ({ id, title, completedTitle, component, totalSubSteps }: any, idx: number) => {
              const activeSubSteps = currentSubStep;
              const lastStep = idx + 1 === steps.length;
              const isCurrentStep = currentStep === id;
              const isPassedStep = checkIsPassedStep(currentStep, id);
              const displayedTitle = isPassedStep && completedTitle ? completedTitle(user.domain) : title;

              return (
                <div key={title} className="relative mb-2">
                  <div
                    className={`absolute w-4 h-4 ${
                      isPassedStep || isCurrentStep
                        ? "bg-accent-primary"
                        : "bg-object-secondary"
                    } rounded-full -left-2 top-5 text-[12px] flex justify-center items-center`}
                  >
                    {idx + 1}
                  </div>
                  {!lastStep && (
                    <div className="absolute w-[2px] h-full bg-object-secondary -left-[1px] top-9 flex flex-col">
                      {Array(totalSubSteps)
                        .fill(1)
                        .map((_, idx) => (
                          <div
                            key={idx}
                            style={{
                              height: `${100 / totalSubSteps}%`,
                            }}
                            className={`w-[2px] ${
                              (isCurrentStep && currentSubStep > idx) ||
                              isPassedStep
                                ? "bg-accent-primary"
                                : "bg-object-secondary"
                            }`}
                          ></div>
                        ))}
                    </div>
                  )}
                  <div className={`pl-4`}>
                    <div
                      className={`w-full flex items-center text-sm ${
                        isCurrentStep ? 'text-text' : isPassedStep ? "text-success" : "text-object-secondary"
                      } font-bold rounded-md px-6 py-4 bg-border ${
                        isCurrentStep ? "rounded-b-none" : "rounded-b-md"
                      }`}
                    >
                      {displayedTitle}{" "}
                      {isPassedStep && (
                        <svg
                          className="ml-2"
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 1L4.125 8L1 4.81818"
                            stroke="#4CAF50"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>

                    <AnimatePresence mode="sync">
                      {isCurrentStep && (
                        <motion.div
                          key="content"
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={{
                            open: { opacity: 1, maxHeight: "1000px" },
                            collapsed: { opacity: 0, maxHeight: 0 },
                          }}
                          transition={{
                            duration: 0.3,
                          }}
                        >
                          <div
                            className={`overflow-hidden bg-highlight rounded-b-md`}
                          >
                            <div className="h-[1px] bg-object-secondary flex">
                              {Array(activeSubSteps)
                                .fill(1)
                                .map((_, idx) => (
                                  <div
                                    key={idx}
                                    style={{
                                      width: `${100 / totalSubSteps}%`,
                                    }}
                                    className={`h-[1px] bg-accent-primary`}
                                  ></div>
                                ))}
                            </div>
                            <div className="p-6">{component()}</div>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              );
            }
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
