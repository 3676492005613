import { PropsWithChildren } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./pages/layout";
import Auth from "./pages/Auth";
import Onboarding from "./pages/Onboarding";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { interceptors } from "./lib/axios";
import { OnboardingProvider } from "./hooks/useOnboarding";
import ResetPassword from "./pages/ResetPassword";
import { GOOGLE_CLIENT_ID } from "./lib/env";
const queryClient = new QueryClient();

function UnboardedRoute({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const appToken = localStorage.getItem("appToken");
  const isNotOnboarded = localStorage.getItem("isOnboarded") === "false";

  if (!user && !appToken) {
    return <Navigate to="/login" replace />;
  }

  if (isNotOnboarded) {
    return <>{children}</>;
  }

  return <Navigate to="/dashboard" replace />;
}

function UnauthenticatedRoute({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const appToken = localStorage.getItem("appToken");
  return user || appToken ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <>{children}</>
  );
}

function PrivateRoute({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const appToken = localStorage.getItem("appToken");
  const isNotOnboarded = localStorage.getItem("isOnboarded") === "false";

  if (!user && !appToken) {
    return <Navigate to="/login" replace />;
  }

  if (isNotOnboarded) {
    return <Navigate to="/onboarding" replace />;
  }

  return <>{children}</>;
}

interceptors();

function App() {
  return (
    <GoogleOAuthProvider clientId={String(GOOGLE_CLIENT_ID)}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Account />
                    </Layout>
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="/billing"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Billing />
                    </Layout>
                  </PrivateRoute>
                }
              /> */}
              <Route path="/reset-password/" element={<ResetPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/login"
                element={
                  <UnauthenticatedRoute>
                    <Auth isLogin={true} />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <UnauthenticatedRoute>
                    <Auth isLogin={false} />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/onboarding"
                element={
                  <UnboardedRoute>
                    <OnboardingProvider>
                      <Onboarding />
                    </OnboardingProvider>
                  </UnboardedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
