import axios, { AxiosRequestHeaders } from "axios";
import { API_URL } from "./env";

export const requestGet: typeof axios.get = (url, config) => {
  return axios.get(url, config);
};

export const requestPost: typeof axios.post = (url, data, config) => {
  return axios.post(url, data, config);
};

export const requestPut: typeof axios.post = (url, data, config) => {
  return axios.put(url, data, config);
};

export const requestPatch: typeof axios.post = (url, data, config) => {
  return axios.patch(url, data, config);
};

export const interceptors = () => {
  axios.interceptors.request.use((config: any) => {
    if (typeof config.headers?.NOINTERCEPT !== "undefined") {
      return config;
    }

    let headers: Partial<AxiosRequestHeaders> = config.headers;

    const { method = "" } = config;

    config.baseURL = String(API_URL);

    if (typeof headers?.noToken === "undefined") {
      const appToken = localStorage.getItem("appToken") ?? null;
      headers = {
        ...headers,
        ...(appToken ? { Authorization: `Token ${appToken}` } : {}),
      };
    } else {
      delete headers.noToken;
    }

    if (["POST", "PATCH"].includes(method)) {
      headers.contentType = "application/json";
    }

    return {
      ...config,
      headers,
    };
  });
};
