import { useInput } from "@/hooks/useInput";
import Button from "../Button";
import DomainInput from "../DomainInput";
import { useOnboarding } from "@/hooks/useOnboarding";

export default function ChooseDomain() {
  const { chooseDomain } = useOnboarding();
  const domain = useInput("");

  const onClick = async () => {
    try {
      await chooseDomain({ domain: `${domain.value}.multik.io` })
    } catch (error) {
      // @ts-ignore
      domain.setError(error?.response?.data?.error_message)
    }
  }

  return (
    <div className="pb-10">
      <div className="text-text text-[12px]">
        Choose a domain in the&nbsp;
        <span className="underline">domain.multik.io</span> zone that will be
        used for your workspace
      </div>
      <div className="my-5">
        <DomainInput domain={domain} />
      </div>
      <div className="float-right">
        <Button
          onClick={onClick}
          text="confirm"
          type={domain.value.length ? "primary" : "disabled"}
          after={
            <svg
              width="5"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L5 5L1 1"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    </div>
  );
}
