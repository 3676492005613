import ChooseDomain from "@/components/Onboarding/ChooseDomain";
import ChoosePlan from "@/components/Onboarding/ChoosePlan";
import DownloadApp from "@/components/Onboarding/DownloadApp";
import GenerateKeys from "@/components/Onboarding/GenerateKeys";
import MakePayment from "@/components/Onboarding/MakePayment";
import VerifyEmail from "@/components/Onboarding/VerifyEmail";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./useAuth";
import { useMutation } from "react-query";
import { requestPost } from "@/lib/axios";

const initialSteps = [
  {
    id: "VERIFICATION",
    title: "Verify your email",
    completedTitle: () => "Email verified",
    totalSubSteps: 2,
    component: () => <VerifyEmail />,
  },
  {
    id: "PLAN",
    title: "Choose a plan",
    totalSubSteps: 1,
    component: () => <ChoosePlan />,
  },
  {
    id: "DOMAIN",
    title: "Choose your domain",
    completedTitle: (domain?: string) => `Domain: ${domain}`,
    totalSubSteps: 1,
    component: () => <ChooseDomain />,
  },
  {
    id: "PROVISION_APP_DOWNLOAD",
    title: "Download Provisioning app",
    totalSubSteps: 3,
    component: () => <DownloadApp />,
  },
  {
    id: "PAYMENT",
    title: "Make a payment",
    totalSubSteps: 3,
    component: () => <MakePayment />,
  },
  {
    id: "PROVISION_PUB_KEY",
    title: "Generate workspace keys",
    totalSubSteps: 3,
    component: () => <GenerateKeys />,
  },
];

const stepsOrder = initialSteps.map(({ id }) => id);
export const checkIsPassedStep = (currentStep: string, checkingStep: string) =>
  stepsOrder.indexOf(checkingStep) < stepsOrder.indexOf(currentStep);

const getInitialSteps = (isGoogleAuth: boolean = false) => {
  return initialSteps.filter(
    ({ id }) => (isGoogleAuth && id !== "VERIFICATION") || !isGoogleAuth
  );
};

interface OnboardingContextProps {
  steps: any;
  currentStep: any;
  currentSubStep: any;
  setCurrentStep: any;
  setCurrentSubStep: any;
  choosePlan: any;
  resendCode: any;
  confirmEmail: any;
  chooseDomain: any;
  setDownloadedApp: any;
  setTransferedPayment: any;
  setProvisionPublicKey: any;
}

const defaultContext: OnboardingContextProps = {
  steps: [],
  currentStep: "VERIFICATION",
  currentSubStep: 1,
  choosePlan: () => null,
  chooseDomain: () => null,
  confirmEmail: () => null,
  resendCode: () => null,
  setCurrentStep: () => null,
  setCurrentSubStep: () => null,
  setDownloadedApp: () => null,
  setTransferedPayment: () => null,
  setProvisionPublicKey: () => null,
};

export const OnboardingContext =
  createContext<OnboardingContextProps>(defaultContext);

export const OnboardingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user, refetchUser } = useAuth();
  const [steps, setSteps] = useState(
    getInitialSteps(localStorage.getItem("isGoogleAuth") === "true")
  );
  const [currentStep, setCurrentStep] = useState(user.step);
  const [currentSubStep, setCurrentSubStep] = useState(1);

  const onSuccess = (data: any) => {
    refetchUser();
    setCurrentStep(data.step);
    setCurrentSubStep(0);
  };

  useEffect(() => {
    setSteps(getInitialSteps(localStorage.getItem("isGoogleAuth") === "true"));
  }, [user]);

  const { mutateAsync: resendCode } = useMutation({
    mutationKey: ["resendCode"],
    mutationFn: async () => {
      try {
        const { data } = await requestPost("/auth/reg-confirm-resend/");
        return data;
      } catch (error) {
        throw error;
      }
    },
  });

  const { mutateAsync: confirmEmail } = useMutation({
    mutationKey: ["confirmEmail"],
    mutationFn: async ({ code }: any) => {
      const { data } = await requestPost("/auth/reg-confirm/", {
        email: user?.email,
        code,
      });
      return data;
    },
    onSuccess: () => {
      refetchUser();
      setCurrentStep("PLAN");
      setCurrentSubStep(0);
    },
  });

  const { mutateAsync: choosePlan } = useMutation({
    mutationKey: ["choosePlan"],
    onSuccess,
    mutationFn: async ({ plan }: { plan: "ADVANCED" | "ENTERPRISE" }) => {
      const { data } = await requestPost("/onboarding/plan/", { plan });
      return data;
    },
  });

  const { mutateAsync: chooseDomain } = useMutation({
    mutationKey: ["chooseDomain"],
    onSuccess,
    mutationFn: async ({ domain }: any) => {
      const { data } = await requestPost("/onboarding/domain/", { domain });
      return data;
    },
  });

  const { mutateAsync: setDownloadedApp } = useMutation({
    mutationKey: ["setDownloadedApp"],
    onSuccess,
    mutationFn: async () => {
      const { data } = await requestPost(
        "/onboarding/provision-app-downloaded/"
      );
      return data;
    },
  });

  const { mutateAsync: setTransferedPayment } = useMutation({
    mutationKey: ["setTransferedPayment"],
    onSuccess,
    mutationFn: async () => {
      const { data } = await requestPost("/onboarding/payment/");
      return data;
    },
  });

  const { mutateAsync: setProvisionPublicKey } = useMutation({
    mutationKey: ["setProvisionPublicKey"],
    onSuccess,
    mutationFn: async ({ pubKey }: any) => {
      const { data } = await requestPost("/onboarding/provision-pub-key/", {
        provision_public_key: pubKey,
      });
      return data;
    },
  });

  return (
    <OnboardingContext.Provider
      value={{
        steps,
        currentStep,
        currentSubStep,
        setCurrentStep,
        setCurrentSubStep,
        choosePlan,
        confirmEmail,
        resendCode,
        chooseDomain,
        setDownloadedApp,
        setTransferedPayment,
        setProvisionPublicKey,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => useContext(OnboardingContext);
