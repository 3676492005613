import Button from "../Button";
import { useOnboarding } from "@/hooks/useOnboarding";

const SuccessTick = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 1L4.125 8L1 4.81818"
        stroke="#4CAF50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const FailedTick = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_179_228)">
        <path
          d="M8 0L0 8"
          stroke="#E64022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0 0L8 8"
          stroke="#E64022"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_179_228">
          <rect width="8" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const tableRows = [
  {
    title: "Intel SGX Enclave ©",
    advanced: <SuccessTick />,
    enterprice: <SuccessTick />,
  },
  {
    title: "Exchange accounts",
    advanced: "5 accounts",
    enterprice: "Unlimited",
  },
  { title: "User seats", advanced: "3 users", enterprice: "Unlimited" },
  {
    title: "Account pools",
    advanced: <FailedTick />,
    enterprice: "Unlimited",
  },
  {
    title: "Account thresholds",
    advanced: <FailedTick />,
    enterprice: <SuccessTick />,
  },
  {
    title: "User spending limits",
    advanced: <FailedTick />,
    enterprice: <SuccessTick />,
  },
  {
    title: "Deployment options",
    advanced: "Cloud",
    enterprice: "Cloud or On-premise",
  },
];

export default function ChoosePlan() {
  const { choosePlan } = useOnboarding();

  return (
    <div>
      <div className="flex justify-between mb-5">
        <div className="w-[257px] text-[14px] font-bold">Choose your plan:</div>
        <div className="w-[257px] flex flex-col items-center">
          <div className="text-[14px] font-bold mb-2">Advanced</div>
          <div className="mb-1 text-[14px] font-bold">
            <span className="text-error line-through text-[20px]">$250</span>{" "}
            <span className="text-[20px]">$100</span>/month{" "}
          </div>
          <div className="text-[12px] text-center mb-5 text-text">
            Some description text here.
            <br />
            More description text.
          </div>
          <div className="w-fit">
            <Button
              text="Choose plan"
              onClick={() => choosePlan({ plan: "ADVANCED" })}
              type="primary"
            />
          </div>
        </div>
        <div className="w-[257px] flex flex-col items-center">
          <div className="text-[14px] font-bold mb-4">Enterprice</div>
          <div className="mb-[9px] text-[14px] font-bold">
            Contact us at sales@multik.io
          </div>
          <div className="text-[12px] text-center mb-[33px] text-text">
            Some description text here.
            <br />
            More description text.
          </div>
          <div>
            <a
              href="https://rw581qtdr3f.typeform.com/to/fiAdPvIk"
              target="_blank"
              className="text-[12px] cursor-pointer font-bold text-accent-primary"
            >
              Fill the interest form
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        {tableRows.map(({ title, advanced, enterprice }, index) => (
          <div
            key={title}
            className={`text-[14px] flex justify-between items-center px-4 py-3 ${
              index % 2 === 0 ? "bg-base" : "bg-transparent"
            }`}
          >
            <div className="w-[257px]">{title}</div>
            <div className="w-[257px] text-center flex justify-center">
              {advanced}
            </div>
            <div className="w-[257px] text-center flex justify-center">
              {enterprice}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
