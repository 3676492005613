import { useObserveElementWidth } from "@/hooks/useObserveElementWidth";

interface DomainInputProps {
  domain: any;
}

export default function DomainInput({ domain }: DomainInputProps) {
  const { width, ref } = useObserveElementWidth({ defaultWidth: 78.625 });
  return (
    <div>
      <div className={`w-[263px] rounded-md h-[30px] border ${domain.error ? 'border-error' : 'border-border'} text-[14px] flex items-center`}>
        <span
          className="whitespace-pre absolute top-0 invisible select-none"
          ref={ref}
        >
          {domain.value}
        </span>
        <input
          value={domain.value}
          onChange={domain.onChange}
          type="text"
          style={{ width: width + "px" }}
          className="outline-none ml-3 bg-transparent"
          placeholder="your-domain"
        />
        <div>.multik.io</div>
      </div>
      <div className="text-error text-[12px] mt-1">{domain.error}</div>
    </div>
  );
}
