import { useAuth } from "@/hooks/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps {
  loading?: boolean;
  onClick?: () => void;
  text: string;
  className?: string;
}

export default function GoogleAuthButton({
  loading = false,
  onClick,
  text,
  className,
}: ButtonProps) {
  const { authWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const cn = twMerge(
    `${
      loading ? "pointer-events-none" : "pointer-events-auto"
    } rounded-full flex relative cursor-pointer border-border bg-transparent text-[12px] font-bold text-primary border justify-between align-center uppercase px-3 py-2`,
    className
  );
  return (
    <div>
      <div className={cn} onClick={onClick}>
        <div className="absolute w-[254px] -top-1 left-0 opacity-0">
          <GoogleLogin
            onSuccess={async ({ credential }) => {
              try {
                await authWithGoogle({ id_token: credential });
              } catch (error) {
                // @ts-ignore
                setError(error?.response?.data?.error_message)
              }
            }}
          />
        </div>
        <div className="w-6">
          <svg
            width="18"
            height="18"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6284 6.27594H13.125V6.25H7.5V8.75H11.0322C10.5169 10.2053 9.13219 11.25 7.5 11.25C5.42906 11.25 3.75 9.57094 3.75 7.5C3.75 5.42906 5.42906 3.75 7.5 3.75C8.45594 3.75 9.32562 4.11062 9.98781 4.69969L11.7556 2.93187C10.6394 1.89156 9.14625 1.25 7.5 1.25C4.04844 1.25 1.25 4.04844 1.25 7.5C1.25 10.9516 4.04844 13.75 7.5 13.75C10.9516 13.75 13.75 10.9516 13.75 7.5C13.75 7.08094 13.7069 6.67187 13.6284 6.27594Z"
              fill="#FFC107"
            />
            <path
              d="M1.9707 4.59094L4.02414 6.09687C4.57977 4.72125 5.92539 3.75 7.50008 3.75C8.45602 3.75 9.3257 4.11062 9.98789 4.69969L11.7557 2.93187C10.6395 1.89156 9.14633 1.25 7.50008 1.25C5.09945 1.25 3.01758 2.60531 1.9707 4.59094Z"
              fill="#FF3D00"
            />
            <path
              d="M7.5001 13.75C9.11447 13.75 10.5813 13.1322 11.6904 12.1275L9.75603 10.4906C9.12853 10.9659 8.34853 11.25 7.5001 11.25C5.87447 11.25 4.49416 10.2134 3.97416 8.76685L1.93604 10.3372C2.97041 12.3612 5.07103 13.75 7.5001 13.75Z"
              fill="#4CAF50"
            />
            <path
              d="M13.6284 6.27594H13.125V6.25H7.5V8.75H11.0322C10.7847 9.44906 10.335 10.0519 9.755 10.4909L9.75594 10.4903L11.6903 12.1272C11.5534 12.2516 13.75 10.625 13.75 7.5C13.75 7.08094 13.7069 6.67188 13.6284 6.27594Z"
              fill="#1976D2"
            />
          </svg>
        </div>
        <div>{text}</div>
        <div className="w-6">
          {loading && (
            <div className="ml-2 animate-spin flex justify-center items-center">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_13_278)">
                  <path
                    d="M12.8332 7.00002C12.8332 10.2217 10.2215 12.8334 6.99984 12.8334C3.77818 12.8334 1.1665 10.2217 1.1665 7.00002C1.1665 3.77836 3.77818 1.16669 6.99984 1.16669"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13_278">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="text-[12px] text-error mt-1">{error}</div>
    </div>
  );
}
