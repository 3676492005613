import { useEffect, useState } from "react";
import Button from "../Button";
import Input from "../Input";
import { useOnboarding } from "@/hooks/useOnboarding";
import { useAuth } from "@/hooks/useAuth";

const paymentStatus = {
  ["VERIFICATION"]: {
    text: "Payment verification",
    color: "text-warning",
  },
  ["RECEIVED"]: {
    text: "Payment received",
    color: "text-success",
  },
  ["NOT_RECEIVED"]: {
    text: "Payment not received",
    color: "text-error",
  },
};

export default function MakePayment() {
  const { user, refetchUser } = useAuth();
  const { setTransferedPayment, setCurrentStep, setCurrentSubStep } =
    useOnboarding();
  const [chosenPlan] = useState("advanced");
  const [transfered, setTransfered] = useState(user?.payment_status !== null);
  // @ts-ignore
  const status = paymentStatus[user?.payment_status];
  const refetchOnStatusChange = async () => {
    const { data } = await refetchUser();
    setCurrentStep(data.step);
    setCurrentSubStep(0);
  };

  useEffect(() => {
    refetchUser();
  }, []);

  const onClick = () => {
    setTransferedPayment();
    setTransfered(true);
  };

  if (transfered) {
    return (
      <div>
        <div className="text-[12px] text-text mb-5">
          <div className="mb-5">Processing your payment... </div>
          <div className="mb-5">
            Status:{" "}
            <span className={`font-bold ${status?.color}`}>{status?.text}</span>
          </div>
          <div>
            If you have any questions about the payment, please contact us via
            email at support@multik.io
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <Button
              type="outlined"
              text="Back"
              onClick={() => setTransfered(false)}
              before={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 1.5L1 4.5L4 7.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
          <div>
            <Button
              onClick={refetchOnStatusChange}
              type={
                user?.payment_status === "RECEIVED" ? "primary" : "disabled"
              }
              text="Next step"
              after={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  if (chosenPlan === "advanced") {
    return (
      <div>
        <div className="flex justify-between text-[12px] text-text mb-5">
          <div className="w-[50%]">
            1. Transfer 100 USDC (ERC-20) from your wallet to the address.{" "}
          </div>
          <div className="w-[50%]">
            2. After making the transfer, click the “I transferred” button.
          </div>
        </div>
        <div className="w-[340px]">
          <Input
            disabled={true}
            type="text"
            copyable={true}
            placeholder="0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
            value="0xDeFFf89752BC4aE304E5c3Aa71E917ae638e47C4"
            onChange={() => null}
          />
        </div>
        <div className="text-error text-[12px] max-w-[400px] mb-5">
          Important! Send funds only on the Ethereum network (ERC-20).
          Transferring cryptocurrency on another network will result in the loss
          of your funds.{" "}
        </div>
        <div className="flex justify-between">
          <div>
            {/* <Button
              type="outlined"
              text="Back"
              before={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 1.5L1 4.5L4 7.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            /> */}
          </div>
          <div>
            <Button
              type="primary"
              onClick={onClick}
              text="I transferred"
              after={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
