import { QRCodeSVG } from "qrcode.react";
export default function DownloadQR() {
  return (
    <QRCodeSVG
      value={
        "https://play.google.com/store/apps/details?id=com.exantech.custody"
      }
      size={68}
    />
  );
}
