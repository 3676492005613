import VerificationInput from "react-verification-input";

const defaultDigitsNumber = 6;

interface CodeInputProps {
  digitsNumber?: number;
  onChange: any;
  value: any;
  error?: string;
}

export default function CodeInput({
  value,
  digitsNumber = defaultDigitsNumber,
  onChange,
  error,
}: CodeInputProps) {
  return (
    <div>
      <VerificationInput
        value={value}
        validChars="1234567890"
        onChange={onChange}
        length={digitsNumber}
        placeholder=""
        classNames={{
          container: "code-input-container",
          character: error
            ? "code-input-character-error"
            : "code-input-character",
          characterInactive: "code-input-character--inactive",
          characterSelected: "code-input-character--selected",
          characterFilled: "code-input-character--filled",
        }}
      />
    </div>
  );
}
