import Button from "@/components/Button";
import ChangePasswordModal from "@/components/ChangePasswordModal";
import Input from "@/components/Input";
import { useAuth } from "@/hooks/useAuth";
import { useInput } from "@/hooks/useInput";

export default function Account() {
  const { user, updateUser, updatingUser } = useAuth();
  const firstName = useInput(user?.first_name);
  const lastName = useInput(user?.last_name);
  const company = useInput(user?.company);
  const phone = useInput(user?.phone_number);

  const onUpdateUser = async () => {
    try {
      await updateUser({
        first_name: firstName.value,
        last_name: lastName.value,
        company: company.value,
        phone_number: phone.value,
      });
    } catch (error) {
      const fields = {
        ["first_name"]: firstName,
        ["last_name"]: lastName,
        ["company"]: company,
        ["phone_number"]: phone,
      };
      const [fieldId, message] =
        // @ts-ignore
        error?.response?.data?.error_message.split(" - ");
      // @ts-ignore
      const field = fields[fieldId];
      field.setError(message);
    }
  };

  return (
    <div className="w-full">
      <div className="text-[14px] mb-16">
        <div className="text-object-primary text-[18px] font-bold mb-5">
          Personal information
        </div>
        <div className="mb-2">
          <div className="w-[100px] mt-2 inline-block">First name: </div>
          <div className="text-text font-bold inline-block">
            <Input
              type="text"
              value={firstName.value}
              onChange={firstName.onChange}
              placeholder="Enter your first name (optional)"
              className="w-[300px] font-normal"
            />
          </div>
        </div>
        <div className="mb-2">
          <div className="w-[100px] mt-2 inline-block">Last name: </div>
          <div className="text-text font-bold inline-block">
            <Input
              type="text"
              value={lastName.value}
              onChange={lastName.onChange}
              placeholder="Enter your last name (optional)"
              className="w-[300px] font-normal"
            />
          </div>
        </div>
        <div className="mb-2">
          <div className="w-[100px] mt-2 inline-block">Company: </div>
          <div className="font-bold text inline-block">
            <Input
              type="text"
              value={company.value}
              onChange={company.onChange}
              placeholder="Enter your company"
              className="w-[300px] font-normal"
            />
          </div>
        </div>
        <div className="mb-2">
          <div className="w-[100px] mt-2 inline-block inline-block">
            Phone:{" "}
          </div>
          <div className="text inline-block">
            <Input
              type="text"
              error={phone.error}
              value={phone.value}
              onChange={phone.onChange}
              placeholder="Enter your phone"
              className="w-[300px] font-normal"
            />
          </div>

          <Button
            onClick={onUpdateUser}
            before={<div className="w-6"></div>}
            after={<div className="w-6"></div>}
            loading={updatingUser}
            type={updatingUser ? "disabled" : "primary"}
            className="mt-5 px-4 py-2 text-object-primary w-[220px]"
            text="Save changes"
          />
        </div>
      </div>
      <div className="text-[14px] mb-16">
        <div className="text-object-primary text-[18px] font-bold mb-5">
          Security
        </div>
        <div className="mb-2 flex items-center">
          <div className="w-[100px] mt-2">Email: </div>
          <div className="text-object-primary font-bold">{user?.email}</div>
        </div>
        <div className="mb-2 flex items-center">
          <div className="w-[100px] mt-2">Password: </div>
          <ChangePasswordModal />
        </div>
      </div>
    </div>
  );
}
