import MenuItem from "./MenuItem";

const menuItems = [
  {
    path: "dashboard",
    icon: ({ active }: any) => (
      <svg
        className={`${
          active ? "stroke-object-primary" : "stroke-object-secondary"
        }`}
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_56_100)">
          <path
            d="M0.625 7.5C0.625 7.5 3.125 2.5 7.5 2.5C11.875 2.5 14.375 7.5 14.375 7.5C14.375 7.5 11.875 12.5 7.5 12.5C3.125 12.5 0.625 7.5 0.625 7.5Z"
            // stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 9.375C8.53553 9.375 9.375 8.53553 9.375 7.5C9.375 6.46447 8.53553 5.625 7.5 5.625C6.46447 5.625 5.625 6.46447 5.625 7.5C5.625 8.53553 6.46447 9.375 7.5 9.375Z"
            // stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_56_100">
            <rect width="15" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    path: "account",
    icon: ({ active }: any) => (
      <svg
        className={`${
          active ? "stroke-object-primary" : "stroke-object-secondary"
        }`}
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 13.125V11.875C12.5 11.212 12.2366 10.5761 11.7678 10.1072C11.2989 9.63839 10.663 9.375 10 9.375H5C4.33696 9.375 3.70107 9.63839 3.23223 10.1072C2.76339 10.5761 2.5 11.212 2.5 11.875V13.125"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.875C8.88071 6.875 10 5.75571 10 4.375C10 2.99429 8.88071 1.875 7.5 1.875C6.11929 1.875 5 2.99429 5 4.375C5 5.75571 6.11929 6.875 7.5 6.875Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  // {
  //   path: "billing",
  //   icon: ({ active }: any) => (
  //     <svg
  //       width="15"
  //       className={`${
  //         active ? "stroke-object-primary" : "stroke-object-secondary"
  //       }`}
  //       height="15"
  //       viewBox="0 0 15 15"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M2 4.25H12C13.1046 4.25 14 5.14543 14 6.25V11.25C14 12.3546 13.1046 13.25 12 13.25H4C2.89543 13.25 2 12.3546 2 11.25V4.25Z"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M2 4C2 2.89543 2.89543 2 4 2H10.5C11.6046 2 12.5 2.89543 12.5 4V4.25H2V4Z"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M8.75 8.75C8.75 7.92157 9.42157 7.25 10.25 7.25H14V10.25H10.25C9.42157 10.25 8.75 9.57843 8.75 8.75V8.75Z"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   path: "helpdesk",
  //   icon: ({ active }: any) => (
  //     <svg
  //       className={`${
  //         active ? "stroke-object-primary" : "stroke-object-secondary"
  //       }`}
  //       width="18"
  //       height="18"
  //       viewBox="0 0 18 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4.65002 9.45C4.65002 10.2456 4.96609 11.0087 5.5287 11.5713C6.09131 12.1339 6.85437 12.45 7.65002 12.45C8.44567 12.45 9.20874 12.1339 9.77134 11.5713C10.334 11.0087 10.65 10.2456 10.65 9.45C10.65 8.65435 10.334 7.89129 9.77134 7.32868C9.20874 6.76607 8.44567 6.45 7.65002 6.45C6.85437 6.45 6.09131 6.76607 5.5287 7.32868C4.96609 7.89129 4.65002 8.65435 4.65002 9.45Z"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M0.900024 9.45C0.900024 10.3364 1.07462 11.2142 1.41384 12.0331C1.75306 12.8521 2.25026 13.5962 2.87705 14.223C3.50385 14.8498 4.24796 15.347 5.06691 15.6862C5.88586 16.0254 6.7636 16.2 7.65002 16.2C8.53645 16.2 9.41419 16.0254 10.2331 15.6862C11.0521 15.347 11.7962 14.8498 12.423 14.223C13.0498 13.5962 13.547 12.8521 13.8862 12.0331C14.2254 11.2142 14.4 10.3364 14.4 9.45C14.4 8.56357 14.2254 7.68583 13.8862 6.86688C13.547 6.04794 13.0498 5.30382 12.423 4.67703C11.7962 4.05023 11.0521 3.55303 10.2331 3.21381C9.41419 2.87459 8.53645 2.7 7.65002 2.7C6.7636 2.7 5.88586 2.87459 5.06691 3.21381C4.24796 3.55303 3.50385 4.05023 2.87705 4.67703C2.25026 5.30382 1.75306 6.04794 1.41384 6.86688C1.07462 7.68583 0.900024 8.56357 0.900024 9.45Z"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M9.90002 11.7L12.4125 14.2125"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M5.39995 11.7L2.88745 14.2125"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M2.88745 4.68752L5.39995 7.20001"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M12.4125 4.68752L9.90002 7.20001"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   ),
  // },
];

export default function Aside() {
  return (
    <div className="flex flex-col min-w-36 mt-6 pl-5">
      {menuItems.map((params) => (
        <MenuItem key={params.path} {...params} />
      ))}
    </div>
  );
}
