import { useState } from "react";
import Button from "../Button";
import { GooglePlayButton } from "react-mobile-app-button";
import DownloadQR from "@/assets/DownloadQR";
import { useOnboarding } from "@/hooks/useOnboarding";

export default function DownloadApp() {
  const { setDownloadedApp } = useOnboarding();
  const [platform, setPlatform] = useState("");
  const APKUrl =
    "https://play.google.com/store/apps/details?id=com.exantech.custody";

  if (platform === "android") {
    return (
      <div>
        <div className="flex justify-center">
          <div className="w-[180px] mb-5 mr-8">
            <div>
              <a
                href="https://pro.multik.app/provisioning/latest.apk"
                target="_blank"
              >
                <Button
                  className="px-4 py-2 mb-2 justify-center"
                  type="primary"
                  rounded={false}
                  text="Install apk"
                  before={
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.7688 5.64125C12.5595 5.64125 12.3922 5.80024 12.3922 5.99897V9.25285C12.3922 10.1379 11.6333 10.8559 10.7043 10.8559H2.44105C1.50927 10.8559 0.753238 10.1352 0.753238 9.25285V5.94597C0.753238 5.74724 0.585852 5.58826 0.376619 5.58826C0.167386 5.58826 0 5.74724 0 5.94597V9.25285C0 10.5327 1.09638 11.5714 2.44105 11.5714H10.7043C12.0518 11.5714 13.1454 10.53 13.1454 9.25285V5.99897C13.1454 5.80289 12.978 5.64125 12.7688 5.64125Z"
                        fill="white"
                      />
                      <path
                        d="M6.3077 8.7309C6.38023 8.7998 6.47787 8.83689 6.57273 8.83689C6.66758 8.83689 6.76522 8.80245 6.83775 8.7309L9.23138 6.45742C9.37923 6.31699 9.37923 6.09176 9.23138 5.95132C9.08352 5.81089 8.84639 5.81089 8.69853 5.95132L6.94935 7.61536V0.357715C6.94935 0.158985 6.78196 0 6.57273 0C6.36349 0 6.19611 0.158985 6.19611 0.357715V7.61536L4.44413 5.95132C4.29627 5.81089 4.05914 5.81089 3.91129 5.95132C3.76343 6.09176 3.76343 6.31699 3.91129 6.45742L6.3077 8.7309Z"
                        fill="white"
                      />
                    </svg>
                  }
                />
              </a>
            </div>
            <div>
              <GooglePlayButton
                url={APKUrl}
                theme={"light"}
                className={"bg-black border-none rounded-md cursor-pointer"}
              />
            </div>
          </div>
          <div className="bg-white h-[90px] p-3 rounded-2xl">
            <DownloadQR />
          </div>
        </div>
        <div className="flex justify-between">
          <div>
            <Button
              onClick={() => setPlatform("")}
              type="outlined"
              text="Back"
              before={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 1.5L1 4.5L4 7.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
          <div>
            <Button
              onClick={() => setDownloadedApp()}
              type="primary"
              text="I downloaded"
              after={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9L5 5L1 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  if (platform === "ios") {
    return (
      <div className="text-[12px] text-text">
        <div className="mb-5">
          Unfortunately, the Provisioning app for iOS is currently under
          development. The app will be available for download within a month.
        </div>
        <div className="mb-5">
          We have received your request and will contact you as soon as the app
          is available in the Apple Store.
        </div>
        <div className="mb-5">
          For now, you can go back and download the app for Android.
        </div>
        <div>
          <div className="w-min">
            <Button
              type="outlined"
              text="Back"
              before={
                <svg
                  width="5"
                  height="9"
                  viewBox="0 0 5 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 1.5L1 4.5L4 7.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              onClick={() => setPlatform("")}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-[12px] text-text mb-5 max-w-[400px]">
        The Multik Provisioning mobile app using to manage your work
        environment. In the app you can add new users, exchange accounts and
        edit whitelisted addresses.
        <div className="mt-5">Please select the platform you are using.</div>
      </div>
      <div className="flex justify-evenly">
        <div>
          <Button
            onClick={() => setPlatform("android")}
            before={
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.85916V14.1405C1.00008 14.1671 1.00803 14.1932 1.02284 14.2153C1.03766 14.2375 1.05868 14.2547 1.08328 14.265C1.10788 14.2752 1.13495 14.278 1.16111 14.2729C1.18727 14.2678 1.21134 14.2551 1.23031 14.2364L7.62504 8.00014L1.23031 1.76322C1.21134 1.74452 1.18727 1.73183 1.16111 1.72675C1.13495 1.72167 1.10788 1.72442 1.08328 1.73467C1.05868 1.74492 1.03766 1.7622 1.02284 1.78434C1.00803 1.80649 1.00008 1.83252 1 1.85916Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                />
                <path
                  d="M10.3063 5.43762L2.28814 1.02009L2.28314 1.01728C2.14501 0.94228 2.01376 1.12916 2.12689 1.23791L8.41224 7.24794L10.3063 5.43762Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                />
                <path
                  d="M2.12753 14.7624C2.01378 14.8711 2.14503 15.058 2.28378 14.983L2.28878 14.9802L10.3063 10.5627L8.41225 8.75171L2.12753 14.7624Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                />
                <path
                  d="M13.5432 7.21888L11.3041 5.98575L9.19879 8.00014L11.3041 10.0136L13.5432 8.78139C14.1523 8.44483 14.1523 7.55545 13.5432 7.21888Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.5"
                />
              </svg>
            }
            type="primary"
            text="Continue with android"
          />
        </div>
        <div>
          <Button
            onClick={() => setPlatform("ios")}
            before={
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0227 7.44517C10.006 5.67648 11.5485 4.81601 11.619 4.77595C10.7454 3.56701 9.39142 3.40183 8.9156 3.38866C7.7784 3.27506 6.6753 4.03456 6.096 4.03456C5.50514 4.03456 4.61306 3.39963 3.65161 3.41829C2.41438 3.4364 1.25694 4.11633 0.622138 5.17216C-0.687935 7.32499 0.289127 10.4887 1.54428 12.2288C2.17214 13.0816 2.9058 14.0326 3.8661 13.9991C4.80558 13.9629 5.15652 13.4312 6.29026 13.4312C7.41359 13.4312 7.74313 13.9991 8.7225 13.9777C9.73079 13.9629 10.3656 13.1217 10.9715 12.2617C11.6971 11.2855 11.9884 10.3229 12 10.2735C11.9769 10.2659 10.0424 9.56453 10.0227 7.44517ZM8.17269 2.24392C8.67799 1.64412 9.02372 0.828095 8.92775 0C8.19639 0.0307312 7.28177 0.480175 6.75508 1.06681C6.2891 1.58375 5.87284 2.43105 5.98037 3.22787C6.80191 3.28604 7.64542 2.8344 8.17269 2.24392Z"
                  fill="white"
                />
              </svg>
            }
            type="primary"
            text="Continue with IOS"
          />
        </div>
      </div>
    </div>
  );
}
