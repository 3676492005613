import Header from "@/components/Header";
import Onboarding from "@/components/Onboarding";

export default function OnboardingPage() {
  return (
    <div className="flex min-w-[852px] justify-center items-center flex-col">
      <Header />
      <Onboarding />
    </div>
  );
}
