import { useState } from "react";
import { twMerge } from "tailwind-merge";

type InputType = "text" | "password" | "textarea";

interface InputProps {
  type: InputType;
  value: string;
  onChange: (e: any) => void;
  className?: string;
  label?: string;
  mask?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  removable?: boolean;
  copyable?: boolean;
}

export default function Input({
  type,
  value,
  onChange,
  className,
  label,
  // mask,
  placeholder,
  error,
  removable = false,
  copyable = false,
  disabled = false,
}: InputProps) {
  const isPassword = type === "password";
  const [hidden, setHidden] = useState(!isPassword);
  const onCopy = () => {
    navigator.clipboard.writeText(value);
  };

  const cn = twMerge(
    `px-3 outline-none text-[12px] w-full py-2 text-primary bg-transparent ${
      error ? "border-error" : "border-border"
    } border rounded-md`,
    className
  );
  return (
    <div className="mb-3 relative">
      {label && <div className="text-[14px] mb-[6px]">{label}</div>}
      {type === "textarea" ? (
        <textarea
          style={{ resize: "none" }}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          className={cn}
          onChange={onChange}
        />
      ) : (
        <input
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          type={isPassword && !hidden ? "password" : "text"}
          className={cn}
          onChange={onChange}
        />
      )}
      <div
        className={`absolute right-2 ${
          label ? "top-[38px]" : "top-[10px]"
        } flex`}
      >
        {removable && value && (
          <div className="cursor-pointer mx-1" onClick={() => onChange("")}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_13_189)">
                <path
                  d="M6.99984 12.8333C10.2215 12.8333 12.8332 10.2217 12.8332 6.99999C12.8332 3.77833 10.2215 1.16666 6.99984 1.16666C3.77818 1.16666 1.1665 3.77833 1.1665 6.99999C1.1665 10.2217 3.77818 12.8333 6.99984 12.8333Z"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.75 5.25L5.25 8.75"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.25 5.25L8.75 8.75"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_13_189">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
        {isPassword && value && (
          <div
            className="cursor-pointer mx-1"
            onClick={() => setHidden(!hidden)}
          >
            {hidden ? (
              <svg
                width="14"
                height="14"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.94545 9.95751C9.75819 10.1601 9.53238 10.3227 9.28147 10.4354C9.03056 10.5481 8.75971 10.6087 8.48506 10.6136C8.21042 10.6185 7.93761 10.5676 7.68292 10.4638C7.42822 10.3601 7.19686 10.2057 7.00262 10.0099C6.80839 9.81401 6.65527 9.58072 6.55239 9.3239C6.44952 9.06708 6.399 8.792 6.40384 8.51507C6.40869 8.23814 6.4688 7.96503 6.5806 7.71203C6.69239 7.45903 6.85358 7.23133 7.05455 7.04251M12.55 12.5838C11.3845 13.4796 9.9653 13.9758 8.5 14C3.72727 14 1 8.50001 1 8.50001C1.84811 6.90631 3.02441 5.51393 4.45 4.41627L12.55 12.5838ZM7.06818 3.16502C7.5375 3.05425 8.018 2.99888 8.5 3.00002C13.2727 3.00002 16 8.50001 16 8.50001C15.5861 9.28074 15.0925 10.0158 14.5273 10.6931L7.06818 3.16502Z"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1L16 16"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.583496 7.00001C0.583496 7.00001 2.91683 2.33334 7.00016 2.33334C11.0835 2.33334 13.4168 7.00001 13.4168 7.00001C13.4168 7.00001 11.0835 11.6667 7.00016 11.6667C2.91683 11.6667 0.583496 7.00001 0.583496 7.00001Z"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        )}
        {copyable && (
          <div onClick={onCopy} className="mx-1 mt-[1px] cursor-pointer">
            <svg
              width="13"
              height="13"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_34_3467)">
                <path
                  d="M8.33333 3.75H4.58333C4.1231 3.75 3.75 4.1231 3.75 4.58333V8.33333C3.75 8.79357 4.1231 9.16667 4.58333 9.16667H8.33333C8.79357 9.16667 9.16667 8.79357 9.16667 8.33333V4.58333C9.16667 4.1231 8.79357 3.75 8.33333 3.75Z"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.08333 6.24998H1.66666C1.44565 6.24998 1.23369 6.16218 1.07741 6.0059C0.921126 5.84962 0.833328 5.63766 0.833328 5.41665V1.66665C0.833328 1.44563 0.921126 1.23367 1.07741 1.07739C1.23369 0.92111 1.44565 0.833313 1.66666 0.833313H5.41666C5.63768 0.833313 5.84964 0.92111 6.00592 1.07739C6.1622 1.23367 6.24999 1.44563 6.24999 1.66665V2.08331"
                  stroke="#B9BEC8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_34_3467">
                  <rect width="10" height="10" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
      </div>
      <div className="text-[12px] text-error mt-1">{error}</div>
    </div>
  );
}
