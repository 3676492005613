import Button from "@/components/Button";
import Header from "@/components/Header";
import Input from "@/components/Input";
import { useAuth } from "@/hooks/useAuth";
import { useInput } from "@/hooks/useInput";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

export default function ResetPassword() {
  const { resetPassword, setUserPassword, signOut, user } = useAuth();
  const { token } = useParams();
  const password = useInput("");
  const confirmPassword = useInput("");
  const email = useInput("");
  const [mailSent, setMailSent] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const onSetPassword = async () => {
    try {
      if (password.value !== confirmPassword.value) {
        password.setError("Passwords do not match");
        confirmPassword.setError("Passwords do not match");
        return;
      }
      await setUserPassword({ password: password.value, passwordToken: token });
      signOut();
      setPasswordChanged(true);
    } catch (error) {
      // @ts-ignore
      password.setError(error?.response?.data?.error_message);
    }
  };

  const onClick = async () => {
    try {
      await resetPassword({ email: email.value });
      setMailSent(true);
    } catch (error) {
      // @ts-ignore
      email.setError(error?.response?.data?.error_message);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <Header />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
        >
          <div className="text-object-primary w-full flex flex-col items-center pb-5">
            <div className="w-[420px] p-8 pb-4 bg-base rounded-md border border-border drop-shadow-xl mt-20">
              {passwordChanged ? (
                <div>
                  <div className="flex font-accent items-center font-bold text-[18px] mb-6">
                    Password successfully changed
                  </div>
                  <div className="font-accent text-[14px] text-text mb-4">
                    Your password has been successfully updated.
                  </div>
                  <div className="font-accent text-[14px] text-text mb-4">
                    You can now log in using your new password.
                  </div>

                  <div className="flex justify-center w-full">
                    <Link to="/login">
                      <Button
                        loading={false}
                        before={<div className="w-6"></div>}
                        after={<div className="w-6"></div>}
                        className="px-2 py-2 mt-6"
                        type={false ? "disabled" : "primary"}
                        text="Login"
                      />
                    </Link>
                  </div>
                </div>
              ) : mailSent ? (
                <div>
                  <div className="flex font-accent items-center font-bold text-[18px] mb-6">
                    Check your email
                  </div>
                  <div className="font-accent text-[14px] text-text mb-4">
                    If an account exists for {email.value}, you will receive an
                    email with instructions on how to reset your password.
                  </div>
                  <div className="font-accent text-[14px] text-text mb-4">
                    If you don't see it in your inbox, please check your spam
                    folder.
                  </div>
                  <div className="flex justify-center w-full">
                    <Link
                      to="/login"
                      className="mt-4 mb-3 block text-center font-bold text-accent-primary text-[14px] cursor-pointer w-fit"
                    >
                      Back to login
                    </Link>
                  </div>
                </div>
              ) : token ? (
                <div>
                  <div className="flex font-accent items-center font-bold text-[18px] mb-6">
                    Confirm a new password
                  </div>
                  <div>
                    <Input
                      removable={true}
                      placeholder="New password"
                      type="password"
                      error={password.error}
                      value={password.value}
                      onChange={password.onChange}
                    />
                  </div>
                  <div>
                    <Input
                      placeholder="Confirm password"
                      type="password"
                      error={confirmPassword.error}
                      value={confirmPassword.value}
                      onChange={confirmPassword.onChange}
                    />
                  </div>
                  <div className="mt-6">
                    <Button
                      loading={false}
                      before={<div className="w-6"></div>}
                      after={<div className="w-6"></div>}
                      onClick={onSetPassword}
                      className="px-2 py-2 mb-4"
                      type={false ? "disabled" : "primary"}
                      text="Submit"
                    />
                  </div>
                  {!user && (
                    <div className="flex justify-center w-full">
                      <Link
                        to="/login"
                        className="block text-center font-bold text-accent-primary text-[14px] cursor-pointer w-fit"
                      >
                        Back to login
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="flex font-accent items-center font-bold text-[18px] mb-4">
                    Enter your email to reset password
                  </div>
                  <div className="font-accent text-[14px] text-text mb-4">
                    We'll send you a link and instructions to reset your
                    password.
                  </div>
                  <div>
                    <Input
                      removable={true}
                      placeholder="Email"
                      type="text"
                      error={email.error}
                      value={email.value}
                      onChange={email.onChange}
                    />
                  </div>
                  <div className="mt-6">
                    <Button
                      loading={false}
                      before={<div className="w-6"></div>}
                      after={<div className="w-6"></div>}
                      onClick={onClick}
                      className="px-2 py-2 mb-4"
                      type={false ? "disabled" : "primary"}
                      text="Reset password"
                    />
                  </div>
                </div>
              )}
            </div>
            {!user && <div className="text-[14px] mt-4">
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="text-center font-bold text-accent-primary cursor-pointer"
              >
                &nbsp;Sign up
              </Link>
            </div>}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
