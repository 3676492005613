import { requestGet, requestPatch, requestPost } from "@/lib/axios";
import axios from "axios";
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthContextProps {
  user: any;
  signOut: any;
  authWithGoogle: any;
  signupWithEmail: any;
  loginWithEmail: any;
  isGoogleLoading: boolean;
  isEmailLoading: boolean;
  refetchUser: any;
  password: string;
  resetPassword: any;
  setUserPassword: any;
  updateUser: any;
  updatingUser: boolean;
}

const defaultContext: AuthContextProps = {
  user: {},
  isGoogleLoading: false,
  isEmailLoading: false,
  refetchUser: () => null,
  signOut: () => null,
  authWithGoogle: () => null,
  signupWithEmail: () => null,
  loginWithEmail: () => null,
  resetPassword: () => null,
  setUserPassword: () => null,
  updateUser: () => null,
  updatingUser: false,
  password: "",
};

export const AuthContext = createContext<AuthContextProps>(defaultContext);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(
    JSON.parse(String(localStorage.getItem("user"))) || undefined
  );

  const { mutateAsync: signupWithEmail, isLoading: loadingSignup } =
    useMutation({
      mutationKey: ["signupWithEmail"],
      mutationFn: async ({ email, password }: any) => {
        const { data } = await requestPost("/auth/reg/", { email, password });
        const user = await loginWithEmail({ email, password });
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        setPassword(password);
        return data;
      },
      onSuccess: () => {
        localStorage.setItem("codeConfirmStartTime", new Date().toISOString());
        localStorage.setItem("isOnboarded", "false");
        navigate("/onboarding");
      },
    });

  const { mutateAsync: resetPassword } = useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: async ({ email }: any) => {
      const { data } = await requestPost("/auth/reset-password-request/", {
        email,
      });
      return data;
    },
  });

  const { mutateAsync: setUserPassword } = useMutation({
    mutationKey: ["setUserPassword"],
    mutationFn: async ({ password, passwordToken }: any) => {
      const { data } = await requestPost("/auth/reset-password/", {
        password_token: passwordToken,
        password,
      });
      return data;
    },
  });

  const { mutateAsync: updateUser, isLoading: updatingUser } = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: async ({
      first_name,
      last_name,
      company,
      phone_number,
    }: any) => {
      const { data } = await requestPatch("/auth/userinfo/", {
        first_name,
        last_name,
        company,
        phone_number,
      });
      return data;
    },
    onSuccess: () => {
      refetchUser();
    },
  });

  const {
    mutateAsync: loginWithEmail,
    data: loginData,
    isLoading: loadingLogin,
  } = useMutation({
    mutationKey: ["loginWithEmail"],
    mutationFn: async ({ email, password }: any) => {
      const { data } = await requestPost("/auth/native/", { email, password });
      setPassword(password);
      return data;
    },
    onSuccess: (data) => {
      localStorage.setItem("appToken", data.token);
      localStorage.setItem("user", JSON.stringify(data));
      setUser(data);
      if (data.step === "FINISHED") {
        navigate("/dashboard");
      } else {
        localStorage.setItem("isOnboarded", "false");
        navigate("/onboarding");
      }
    },
  });

  const { mutateAsync: authWithGoogle, isLoading: loadingGoogleAuth } =
    useMutation({
      mutationKey: ["googleAuth"],
      mutationFn: async ({ id_token }: any) => {
        const { data } = await requestPost("/auth/google/", { id_token });
        return data;
      },
      onSuccess: (data) => {
        localStorage.setItem("appToken", data.token);
        localStorage.setItem("isGoogleAuth", "true");
        localStorage.setItem("user", JSON.stringify(data));
        setUser(data);
        if (
          data.step === "FINISHED" ||
          data.provision_setup_status === "DONE"
        ) {
          navigate("/dashboard");
        } else {
          localStorage.setItem("isOnboarded", "false");
          navigate("/onboarding");
        }
      },
    });

  const signOut = () => {
    setUser(undefined);
    localStorage.removeItem("isOnboarded");
    localStorage.removeItem("isGoogleAuth");
    localStorage.removeItem("appToken");
    localStorage.removeItem("user");
    localStorage.removeItem("codeConfirmStartTime");
    if (location.pathname.includes("reset-password")) {
      return;
    }
    navigate("/login");
  };

  const { refetch: refetchUser } = useQuery({
    queryKey: ["getUser", localStorage.getItem("appToken")],
    queryFn: async () => {
      const { data } = await requestGet("/auth/userinfo/");
      return data;
    },
    enabled:
      Boolean(localStorage.getItem("appToken")) &&
      (loginData?.is_verified || user?.is_verified) &&
      !Boolean(localStorage.getItem("isGoogleAuth")),
    onSuccess: (data) => {
      localStorage.setItem("user", JSON.stringify(data));
      setUser(data);
      const isOnboarded = localStorage.getItem("isOnboarded");
      if (isOnboarded && data?.step === "FINISHED") {
        localStorage.removeItem("isOnboarded");
      }
    },
  });

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          signOut();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  const isEmailLoading = loadingLogin || loadingSignup;
  const isGoogleLoading = loadingGoogleAuth;

  return (
    <AuthContext.Provider
      value={{
        authWithGoogle,
        signupWithEmail,
        loginWithEmail,
        signOut,
        refetchUser,
        setUserPassword,
        user,
        isEmailLoading,
        isGoogleLoading,
        password,
        resetPassword,
        updateUser,
        updatingUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
