import { useAuth } from "@/hooks/useAuth";
import Profile from "./Profile";
import Logo from "@/assets/icons/logo.svg";

export default function Header() {
  const { user } = useAuth();
  const email = localStorage.getItem("email");

  const isLoggedIn = email || user;
  return (
    <div className="text-object-primary w-full">
      <div
        className={`flex ${
          isLoggedIn ? "justify-between" : "flex-start"
        } items-center max-w-7xl m-auto px-8 py-6`}
      >
        <div className="cursor-pointer">
          <img src={Logo} alt="logo" className="w-32" />
        </div>
        {isLoggedIn && <Profile />}
      </div>
    </div>
  );
}
